/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  min-height: 60px;
  background-color: #f5f5f5;
  padding: 20px;
}

/* Custom page CSS
-------------------------------------------------- */
