body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
a {
  text-decoration: none;
}

.main-content {
  margin-bottom: 100px;
}
.logo-top {
  max-width: 500px;
  margin: 20px auto 0 auto;
  padding: 30px;
}
.logo-top img {
  width: 100%;
}
.container {
  margin: 20px;
}

.home {
  font-size: large;
  max-width: 900px;
  margin: 20px auto 0 auto;
  text-align: center;
}

.form-container {
  font-size: large;
  max-width: 900px;
  margin: 20px auto 0 auto;
  background-color: #eee;
  border-radius: 6px;
  padding: 20px;
}

#loader-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: -1;
}
#loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-left: -100px;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.quark-list-container {
  padding: 13px;
}
.baryon-body {
  text-align: left;
}
.baryon-body:after {
  content: "";
  clear: both;
  display: block;
}

.baryon-grid {
  float: left;
}
.property-box h2 {
  padding-left: 16px;
  margin-bottom: 0px;
}
@media (max-width: 970px) {
  .baryon-grid {
    float: none;
  }
  .baryon-properties {
    margin-top: 20px;
  }
}
@media (min-width: 970px) {
  .baryon-body {
    padding: 20px;
  }
  .baryon-subject {
    margin-right: 20px;
  }
  .baryon-subject {
    width: 216px;
  }
  .baryon-properties {
    width: 646px;
  }
}
@media (min-width: 1080px) {
  .baryon-subject {
    width: 266px;
  }
  .baryon-properties {
    width: 716px;
  }
}
@media (min-width: 1150px) {
  .baryon-subject {
    width: 266px;
  }
  .baryon-properties {
    width: 766px;
  }
}
@media (min-width: 1240px) {
  .baryon-subject {
    width: 316px;
  }
  .baryon-properties {
    width: 816px;
  }
}

.baryon-subject {
}
.baryon-subject-image-wrapper {
}

.baryon-subject-image {
  width: 350px;
  /* height: 300px; */
  object-fit: cover;
}

.baryon-gluon-body {
}

.baryon-gluon-image {
  width: 200px;
  /* height: 200px; */
  object-fit: cover;
  object-position: 50% 0%;
}

.baryon-interaction-body {
}

.baryon-strong-interaction {
}

button.link-style-btn {
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background: none;
  color: #0033cc;
}
button.link-style-btn:hover {
  text-decoration: underline;
  color: #002080;
}
